jQuery(document).ready(function ($) {
  'use strict';
  //  TESTIMONIALS CAROUSEL HOOK
  $('#why-us-characteristics').owlCarousel({
    margin: 100,
    autoplay: true,
    autoplayTimeout: 8000,
    autoplayHoverPause: true,
    smartSpeed: 450,
    dotsSpeed: 450,
    navigation: false,
    responsiveClass: true,
    items: 1,
    responsive: {
      768: {
        items: 2,
        autoplay: false,
        margin: 30,
        mouseDrag: false,
        touchDrag: false,
        pullDrag: false,
      },
    },
  });
});
